<template>
    <div>
        <div class="header">
            <span class="header_txt">活动列表</span>
        </div>
        <div class="header_box">
            <div class="left">
                <span class="header_span">活动类型：</span>
                <Select v-model="storePrams.type" style="width:200px" @on-change="activityType">
                    <Option v-for="item in liveList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <span class="header_span">状态：</span>
                <Select v-model="storePrams.status" style="width:200px" @on-change="state">
                    <Option v-for="item in shopList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="right">
                <!-- <button class="header_btn" @click="withActivity" style="margin-right: 10px;">关联活动</button> -->
                <button class="header_btn" @click="addActivity">添加活动</button>
            </div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" :no-data-text="noDataText" border>
                <template slot-scope="{row}" slot="cover">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center;">
                        <img v-if="row.cover_id" :src="row.cover.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{row}" slot="live">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center;">
                        <img v-if="row.live_id" :src="row.live.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{row}" slot="type">
                    <span v-if="row.type">{{liveList[row.type - 1].label}}</span>
                </template>
                <template slot-scope="{row}" slot="status">
                    <span v-if="row.original_status == 0" style="color:#e6a23c">{{row.status}}</span>
                    <span v-if="row.original_status == 1" style="color:#409eff">{{row.status}}</span>
                    <span v-if="row.original_status == 2" style="color:rgb(144, 147, 153)">{{row.status}}</span>
                </template>
                <template slot-scope="{row}" slot="types">
                    <div style="display:flex;align-items:center;justify-content:space-around;cursor: pointer;">
                        <span style="color:#409eff" @click="emit(row)">编辑</span>
                        <span v-if="row.original_status == 0" style="color:#409eff" @click="startActivity(row)">开始活动</span>
                        <span v-if="row.original_status == 1" style="color:#f00" @click="endActivity(row)">结束活动</span>
                    </div>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 添加活动弹框 -->
        <Modal v-model="activityModal" title="活动信息" width="80%">
            <div>
                <Form ref="activityListForm" :model="activityListForm" :label-width="200">
                    <FormItem label="活动/商品名称:" prop="name">
                        <Input type="text" v-model="activityListForm.name" style="width: 300px;" placeholder="输入活动/商品名称"></Input>
                    </FormItem>
                    <FormItem label="特价开始结束时间:" prop="start_time">
                        <DatePicker type="datetimerange" @on-change="chooseDate" placement="bottom-end" placeholder="请选择时间" style="width: 300px;" :value="dateValue"></DatePicker>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 4" label="是否开启预约">
                        <RadioGroup v-model="activityListForm.is_book_switch">
                            <Radio v-for="item in orderList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 3 || activityListForm.type == 5 || activityListForm.type == 6" label="活动类型:" prop="type">
                        <RadioGroup v-model="activityListForm.type">
                            <Radio v-for="item in workOrderList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 5" label="门票秒杀图:">
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="seckillSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div class="form_upload">
                                <img v-if="activityListForm.sale_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="activityListForm.sale.path" alt="">
                                <Icon v-else class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 6 || activityListForm.is_book_switch == 1" label="预热开始时间" prop="preheat_start_time">
                        <DatePicker type="datetime" placeholder="预热开始时间" style="width: 300px" @on-change="startDate" :value="startValue"></DatePicker>
                    </FormItem>
                    <FormItem label="活动售价:" prop="money">
                        <Input type="text" v-model="activityListForm.money" style="width: 300px;" placeholder="输入活动售价"></Input>
                    </FormItem>
                    <FormItem label="活动权重:">
                        <Input type="text" v-model="activityListForm.sort" style="width: 300px;" placeholder="输入活动权重(越小优先显示)"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 4" label="定金:">
                        <Input type="text" v-model="activityListForm.deposit_money" style="width: 300px;" placeholder="输入定金"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 4" label="定金抵扣:">
                        <Input type="text" v-model="activityListForm.deposit_deduction" style="width: 300px;" placeholder="输入定金抵扣"></Input>
                    </FormItem>
                    <FormItem label="活动限额:" prop="activity_limits">
                        <Input type="text" v-model="activityListForm.activity_limits" style="width: 300px;" placeholder="输入活动限额"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 1" label="购买限额:" prop="buy_limits">
                        <Input type="text" v-model="activityListForm.buy_limits" style="width: 300px;" placeholder="1" disabled></Input>
                    </FormItem>
                    <FormItem v-else label="购买限额:" prop="buy_limits">
                        <Input type="text" v-model="activityListForm.buy_limits" style="width: 300px;" placeholder="输入购买限额"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 1" label="助力人数" prop="helpe_number">
                        <Input type="text" v-model="activityListForm.helpe_number" style="width: 300px;" placeholder="助力人数"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 1" label="主标题:" prop="title_one">
                        <Input type="text" v-model="activityListForm.title_one" style="width: 300px;" placeholder="输入主标题"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 1" label="副标题:">
                        <Input type="text" v-model="activityListForm.title_two" style="width: 300px;" placeholder="输入副标题"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type == 2" label="成团人数:" prop="group_size">
                        <Input type="text" v-model="activityListForm.group_size" style="width: 300px;" placeholder="成团人数"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type != 1" label="加入显示销量:" prop="false_sales">
                        <Input type="text" v-model="activityListForm.false_sales" style="width: 300px;" placeholder="加入显示销量"></Input>
                    </FormItem>
                    <FormItem v-if="activityListForm.type != 1" label="到账方式:" prop="way">
                        <RadioGroup v-model="wayValue">
                            <Radio v-for="item in wayList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem label="跳转终身卡小程序:">
                        <RadioGroup v-model="jumpValue">
                            <Radio v-for="item in jumpList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem>
                    <!-- <FormItem v-if="activityListForm.type != 3" label="分销设置:" prop="distribution">
                        <RadioGroup v-model="setupValue">
                            <Radio v-for="item in setupList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem> -->
                    <FormItem v-if="setupValue == 1 || setupValue == 2" label="直接分享人:">
                        <Input type="text" style="width: 100px;" placeholder="最小值"></Input> ~ <Input type="text" style="width: 100px;" placeholder="最大值"></Input>
                        <button class="header_btn" style="width:50px;padding: 0;margin-left: 10px;">%</button>
                    </FormItem>
                    <FormItem v-if="setupValue == 1 || setupValue == 2" label="间接分享人:">
                        <Input type="text" style="width: 100px;" placeholder="最小值"></Input> ~ <Input type="text" style="width: 100px;" placeholder="最大值"></Input>
                        <button class="header_btn" style="width:50px;padding: 0;margin-left: 10px;">%</button>
                    </FormItem>
                    <hr/>
                    <div class="activityModal_box">
                        <h4>优惠总价必须大于售价</h4>
                        <div class="activityModal_btn" v-for="(item,index) in preferential" :key="index">
                            <span>优惠名称:</span>
                            <Input type="text" v-model="item.name" style="width: 200px;" placeholder="请输入内容"></Input>
                            <span>价格:</span>
                            <Input type="text" v-model="item.price" style="width: 200px;" placeholder="请输入内容"></Input>
                            <span>数量:</span>
                            <Input type="text" v-model="item.number" style="width: 200px;" placeholder="请输入内容"></Input>
                            <!-- <i class="iconfont icon-shanchu"  style="font-size:40px;color:#f00;cursor: pointer;" @click="deleteBtn(index)"></i> -->
                            <img src="@/assets/icon/delete.png" style="cursor: pointer;" @click="deleteBtn(index)" alt="">
                        </div>
                        <Button class="header_btn" @click.stop="addPreferential" style="width:145px;margin: 10px 0;padding: 0;"> + 添加优惠项目</Button>
                        <!-- <FormItem prop="project"></FormItem> -->
                        <!-- <FormItem label="选择上传模式:">
                            <RadioGroup v-model="modeValue">
                                <Radio v-for="item in modeList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                            </RadioGroup>
                        </FormItem> -->
                    </div>
                    <hr>
                    <FormItem></FormItem>
                    <!-- <FormItem v-if="modeValue == 1" label="顶部概述图">
                        <div class="form_mask">
                            <div v-for="(item, index) of activityListForm.top" class="mask_upload" :key="index">
                                <img :src="item.img.path" alt="" class="mask_img"/>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="maskDelete(item,index)"/>
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="topSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </FormItem> -->
                    <FormItem label="虚拟点赞数:">
                        <Input type="text" v-model="activityListForm.virtual_likes" style="width: 300px;"></Input>
                    </FormItem>
                    <FormItem label="虚拟转发数:">
                        <Input type="text" v-model="activityListForm.virtual_forwarding" style="width: 300px;"></Input>
                    </FormItem>
                    <FormItem label="视频封面图">
                        <h4>(单图大小100kb内)</h4>
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="videotopSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div class="form_upload">
                                <img v-if="activityListForm.video_corver_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="activityListForm.videocover.path" alt="">
                                <Icon v-else class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="视频">
                        <h4>(视频最大128M)</h4>
                        <div class="form_mask">
                            <div v-for="(item, index) of activityListForm.video" class="mask_upload" :key="index">
                                <video :src="item.video.path" class="mask_img"></video>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="videoDelete(item,index)"/>
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['mp4', 'avi', 'mov']"
                            :max-size="128 * 1024"
                            :on-format-error="handleFormatError"
                            :on-success="videoSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadVideo"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                    <FormItem label="助力视频">
                        <h4>(视频最大128M)</h4>
                        <div class="form_mask">
                            <div v-for="(item, index) of activityListForm.helpvideo" class="mask_upload" :key="index">
                                <video :src="item.video.path" class="mask_img"></video>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="videoDeletes(item,index)"/>
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['mp4', 'avi', 'mov']"
                            :max-size="128 * 1024"
                            :on-format-error="handleFormatError"
                            :on-success="helpSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadVideo"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                    <FormItem label="封面图">
                        <h4>(单图大小100kb内)</h4>
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="coverSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div class="form_upload">
                                <img v-if="activityListForm.cover_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="activityListForm.cover.path" alt="">
                                <Icon v-else class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="直播间显示图">
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="liveshowSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <img v-if="activityListForm.live_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="activityListForm.live.path" alt="">
                            <Icon v-else class="form_icon" type="md-add" />
                        </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="活动详情尾部显示图">
                        <div class="form_mask">
                            <div v-for="(item, index) of activityListForm.intros" class="mask_upload" :key="index">
                                <img :src="item.img.path" alt="" class="mask_img"/>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="maskDelete(item,index)"/>
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="bottomSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                    <!-- <FormItem v-if="modeValue == 1" label="助力活动详情尾部显示图">
                        <div class="form_mask">
                            <div v-for="(item, index) of activityListForm.help" class="mask_upload" :key="index">
                                <img :src="item.img.path" alt="" class="mask_img"/>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="maskDelete(item,index)"/>
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="helpeSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </FormItem> -->
                </Form>
            </div>
            <div slot="footer">
                <Button @click="activityModalF">取消</Button>
                <Button type="primary" @click="activityModalT">确定</Button>
            </div>
        </Modal>
        <!-- 关联活动弹框 -->
        <Modal v-model="withModal" title="关联活动" width="60%">
            <div>
                <div class="withModal_header">
                    <span>快捷查询</span>
                    <Input type="text" style="width: 300px;margin: 0 20px;" placeholder="请输入内容"></Input>
                    <Button type="primary" style="width: 70px;">查询</Button>
                </div>
                <Table :columns="withTable" :data="withDataTable">
                    <template slot-scope="{ row, index }" slot="select">
                        <Radio></Radio>
                    </template>
                </Table>
                <div class="footer" style="margin-top: 10px;">
                    <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
                </div>
                <div class="withModal_foot">
                    <p>选择主题</p>
                    <RadioGroup v-model="selectTheme" type="button">
                        <Radio label="large">哈哈</Radio>
                        <Radio label="default">嘿嘿</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div slot="footer">
                <Button @click="withModalF">取消</Button>
                <Button type="primary" @click="withModalT">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import config from "@/config/index";
import { getCookies } from "@/utils/helper";
import {activeList, activeCreate, modifyStatus} from '@/api/index'
    export default {
        data() {
            return {
                orderList:[
                    {label:'关',value:'0'},
                    {label:'开',value:'1'}
                ],
                // orderValue:'1',
                modeList:[
                    {label:'图文模式',value:'1'},
                    {label:'视频模式',value:'2'}
                ],
                modeValue:'1',
                setupList:[
                    {label:'直到分销',value:'1'},
                    {label:'主提分销',value:'2'},
                    {label:'无',value:'3'}
                ],
                setupValue:'3',
                jumpList:[
                    {label:'是',value:'1'},
                    {label:'否',value:'2'}
                ],
                jumpValue:'2',
                wayList:[
                    {label:'即买即到',value:'1'},
                    {label:'核销/收货',value:'2'},
                ],
                wayValue:'2',
                dateValue:'',
                startValue:'',
                workOrderList:[
                    {label:'限时特价',value:'3'},
                    {label:'门票秒杀',value:'5'},
                    {label:'活动预热',value:'6'}
                ],
                uploadImg: config.apiUrl + "/admin/other/uploads",
                uploadVideo: config.apiUrl + "/admin/other/uploads_video",
                headers: {"meiye-admin-api": getCookies("meiye-admin-api")},
                liveList: [
                    {value: '1',label: '种草活动'},
                    {value: '2',label: '拼团活动'},
                    {value: '3',label: '限时特价'},
                    {value: '4',label: '定金预售'},
                    {value: '5',label: '秒杀活动'},
                    {value: '6',label: '活动预约'}
                ],
                shopList: [
                    {value: '0',label: '未开始'},
                    {value: '1',label: '进行中'},
                    {value: '2',label: '已结束'}
                ],
                //table
                liveTable: [
                    {title: '活动名称',key: 'name',align:'center'},
                    {title: '封面图',slot: 'cover',align:'center'},
                    {title: '直播间显示图',slot: 'live',align:'center'},
                    {title: '活动权重',key: 'sort',align:'center'},
                    {title: '活动类型',slot: 'type',align:'center'},
                    {title: '状态',slot: 'status',align:'center'},
                    {title: '开始时间',key: 'start_time',align:'center'},
                    {title: '结束时间',key: 'end_time',align:'center'},
                    {title: '操作',slot: 'types',align:'center'}
                ],
                dataTable: [],
                withTable: [
                    {title: '品项名称',key: 'name',align:'center'},
                    {title: '院方名称',key: 'hospitalName',align:'center'},
                    {title: '选择',slot: 'select',align:'center'}
                ],
                withDataTable: [
                    {name: '9D',hospitalName: 'C院方',select: ''},
                    {name: '8D',hospitalName: 'B院方',select: ''},
                    {name: '7D',hospitalName: 'A院方',select: ''},
                    {name: '6D',hospitalName: 'A院方',select: ''}
                ],
                //page
                total: 0,
                totalModal:10,
                storePrams: {
                    page: 1,
                    limit: 30,
                    type:'1',
                    status:'1'
                },
                storePramsModal: {
                    page: 1,
                    limit: 30,
                },
                noDataText:'暂无数据',
                activityModal:false,
                activityListForm:{},
                withModal:false,
                //选择主题
                selectTheme:'',
                preferential:[
                    // {name:'', price:'', number:''}
                ],
            }
        },
        mounted(){
            this.activeList()
        },
        methods: {
            // 删除视频
            videoDelete(item,i){
                this.activityListForm.video.splice(i,1)
            },
            videoDeletes(item,i){
                this.activityListForm.helpvideo.splice(i,1)
            },
            // 删除图片
            maskDelete(item,i){
                this.activityListForm.intros.splice(i,1)
            },
            chooseDate(e){
                this.dateValue = e
                if(e[0]){
                    this.activityListForm.start_time = e[0]
                    this.activityListForm.end_time = e[1]
                }else {
                    this.activityListForm.start_time = ''
                    this.activityListForm.end_time = ''
                }
            },
            startDate(e){
                this.startValue = e
            },
            startActivity(item){
                console.log('item',item)
                let data = {
                    active_id: item.id,
                    status: item.original_status + 1
                }
                modifyStatus(data).then(res => {
                    this.activeList()
                    this.$Message.success(res.msg);
                })
            },
            endActivity(item){
                let data = {
                    active_id: item.id,
                    status: item.original_status + 1
                }
                modifyStatus(data).then(res => {
                    this.activeList()
                    this.$Message.success(res.msg);
                })
            }, 
            activityType(e){
                this.storePrams.type = e
                this.activeList()
            },
            // 状态
            state(e){
                this.storePrams.status = e
                this.activeList()
            },
            // 活动列表
            activeList(){
                activeList(this.storePrams).then(res => {
                    console.log('活动列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            // 关联活动
            withActivity(){
                this.withModal = true
            },
            addActivity(){
                this.activityModal = true
                this.activityListForm.type = this.storePrams.type
                this.activityListForm.name = ""
                this.activityListForm.start_time = ""
                this.activityListForm.end_time = ""
                this.dateValue = []
                this.activityListForm.money = ""
                this.activityListForm.sort = ""
                this.activityListForm.activity_limits = ""
                this.activityListForm.buy_limits = ""
                this.activityListForm.false_sales = ""
                this.activityListForm.virtual_likes = ""
                this.activityListForm.virtual_forwarding = ""
                this.activityListForm.deposit_money = ""
                this.activityListForm.deposit_deduction = ""
                this.activityListForm.group_size = ""
                this.activityListForm.video_corver_id = ''
                this.activityListForm.videocover = {}
                this.activityListForm.video = []
                this.activityListForm.cover_id = ''
                this.activityListForm.cover = {}
                this.activityListForm.live_id = ''
                this.activityListForm.live = {}
                this.activityListForm.intros = []
                this.activityListForm.helpvideo = []
                this.activityListForm.sale_id = ''
                this.activityListForm.sale = {}
                this.startValue = ''
                this.preferential = []
                this.activityListForm.id = ''
            },
            emit(item){
                console.log('编辑',item)
                this.activityModal = true
                this.activityListForm = item
                this.dateValue = [item.start_time, item.end_time]
                this.activityListForm.type = this.storePrams.type
                this.preferential = item.project_json
                this.activityListForm.id = item.id
                if(!this.activityListForm.live_id){
                    this.activityListForm.live = {}
                }
                if(!this.activityListForm.video_corver_id){
                    this.activityListForm.videocover = {}
                }
                if(!this.activityListForm.cover_id){
                    this.activityListForm.cover = {}
                }
                if(!this.activityListForm.sale_id){
                    this.activityListForm.sale = {}
                }
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            activityModalT(){
                var intro_ids =[]
                this.activityListForm.intros.forEach((item) => {
                    intro_ids.push(item.img_id)
                })
                this.activityListForm.bottom_id = intro_ids.join(",")
                var video_ids =[]
                this.activityListForm.video.forEach((item) => {
                    video_ids.push(item.video_id)
                })
                this.activityListForm.video_id = video_ids.join(",")
                var help_video_ids =[]
                this.activityListForm.helpvideo.forEach((item) => {
                    help_video_ids.push(item.video_id)
                })
                this.activityListForm.help_video_id = help_video_ids.join(",")
                let data = {
                    name: this.activityListForm.name,
                    sort: this.activityListForm.sort,
                    start_time: this.activityListForm.start_time,
                    end_time: this.activityListForm.end_time,
                    type: this.activityListForm.type,
                    sale_id: this.activityListForm.sale_id,
                    money: this.activityListForm.money,
                    deposit_money: this.activityListForm.deposit_money,
                    deposit_deduction: this.activityListForm.deposit_deduction,
                    activity_limits: this.activityListForm.activity_limits,
                    false_sales: this.activityListForm.false_sales,
                    helpe_number: this.activityListForm.helpe_number,
                    title_one: this.activityListForm.title_one,
                    title_two: this.activityListForm.title_two,
                    group_size: this.activityListForm.group_size,
                    virtual_likes: this.activityListForm.virtual_likes,
                    virtual_forwarding: this.activityListForm.virtual_forwarding,
                    video_corver_id: this.activityListForm.video_corver_id,
                    video_id: this.activityListForm.video_id,
                    help_video_id: this.activityListForm.help_video_id,
                    cover_id: this.activityListForm.cover_id,
                    live_id: this.activityListForm.live_id,
                    id: this.activityListForm.id,
                    buy_limits: this.activityListForm.buy_limits,
                    preheat_start_time: this.startValue, //时间
                    bottom_id: this.activityListForm.bottom_id,
                    // top_id: this.activityListForm.top_id,
                    // helpe_id: this.activityListForm.helpe_id,
                    project_json: this.preferential
                }
                activeCreate(data).then(res => {
                    this.activityModal = false
                    this.activeList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                }); 
            },
            activityModalF(){
                this.activityModal = false
                this.activeList()
            },
            addPreferential(){
                this.preferential.push({name:'', price:'', number:''})
            },
            deleteBtn(i){
                this.preferential.splice(i,1)
            },
            withModalF(){
                this.withModal = false
            },
            withModalT(){
                this.withModal = false
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress(event, file, fileList) {
                if(event.percent == 100){
                    const msg = this.$Message.loading({
                        content: "上传中...",
                        duration: 0,
                    });
                    setTimeout(msg, 3000);
                }
            },
            // 门票秒杀图上传 
            seckillSuccess(e) {
                console.log("门票秒杀图上传", e);
                this.activityListForm.sale.path = e.data.path
                this.activityListForm.sale_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 顶部概述图上传
            topSuccess(e) {
                console.log('eee',e)
                this.activityListForm.top.push({'img_id' : e.data.id, 'img':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 视频封面图上传
            videotopSuccess(e){
                console.log('eee',e)
                this.activityListForm.videocover.path = e.data.path
                this.activityListForm.video_corver_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 视频上传
            videoSuccess(e){
                console.log('eee',e)
                this.activityListForm.video.push({'video_id' : e.data.id, 'video':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            //封面图上传
            coverSuccess(e){
                this.activityListForm.cover.path = e.data.path
                this.activityListForm.cover_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 助力视频上传
            helpSuccess(e){
                this.activityListForm.helpvideo.push({'video_id' : e.data.id, 'video':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 直播间显示图上传
            liveshowSuccess(e){
                this.activityListForm.live.path = e.data.path
                this.activityListForm.live_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 活动详情尾部显示图
            bottomSuccess(e){
                this.activityListForm.intros.push({'img_id' : e.data.id, 'img':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 助力活动详情尾部显示图
            // helpeSuccess(e) {
            //     console.log("图片上传", e);
            //     this.activityListForm.help.push({'img_id' : e.data.id, 'img':{'path':e.data.path}});
            //     this.$Message.destroy();
            //     this.$Message.success(e.msg);
            // },
        },
    }
</script>

<style lang="scss" scoped>
.form_mask .form_upload:hover{
    border: 1px dashed #409eff;
}
.form_mask{
    display:flex;
    flex-wrap:wrap;
    width: 100%;
}
.mask_upload{
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    position: relative;
}
.mask_img{
    width: 150px;
    height:150px;
    border-radius: 10px;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background: rgba(101, 101, 101, 0.6);
    color: #ffffff;
    opacity: 0;
    border-radius: 10px;
}
.mask .mask_icon {
    font-size: 20px;
}
.mask_upload a:hover .mask {
    opacity: 1; 
    text-align: center;  
    height: 150px;
    line-height: 150px;        
}
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 30px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.right{
    display: flex;
    flex-direction: row;
}
.content{
    margin: 20px 0;
}
.footer{
    text-align: center;
}
.header_span{
    font-size: 16px;
    font-weight: 700;
    margin: 0 10px 0 10px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.activityModal_box{
    margin: 20px 0;
}
.form_upload{
    width: 150px;
    height: 150px;
    border: 1px dashed #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.form_upload:hover{
    border: 1px dashed #409eff;
}
.form_icon{
    font-size: 30px;
    color: #808080;
}
.activityModal_btn{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
}
.withModal_header{
    margin: 10px 0;
}
.withModal_foot{
    margin: 10px 0 40px 0;
}
</style>